import { useEffect, useState, useCallback, useMemo } from 'react';
import PropTypes from 'prop-types';
import dayjs from 'dayjs';

import {
  Dialog,
  DialogActions,
  DialogContent,
  Typography,
  Grid,
  Tabs,
  Tab,
  Box,
  Button,
  List,
  ListItem,
  ListItemText,
  ListItemIcon,
  Divider,
  IconButton,
  Stack
} from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
// import SettingsIcon from '@mui/icons-material/Settings';

import { DataGrid } from '@mui/x-data-grid';

import callAzureFunction from 'utils/callAzureFunctions';
import { tabOptions } from 'utils/constants';
import { formatStripeAmount } from 'utils/transaction-helpers';

// import MenuActionButton from 'ui-components/buttons/MenuActionButton';
import StripeTransferDialog from './StripeTransferDialog';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import PaymentConfirmationDialog from './PaymentConfirmationDialog';

import TransferHistory from './accrual-tab-content/TransferHistory';
import { enqueueSnackbar } from 'notistack';

import ManualPayment from './accrual-tab-content/ManualPayment';
import PaymentBreakdown from './accrual-table-content/PaymentBreakdown';

const StripeInvoiceCell = ({ stripeInvoices }) => {
  const [isCollapsed, setIsCollapsed] = useState(true);

  const formatAmount = (amountInCents) =>
    (amountInCents / 100).toLocaleString('en-US', {
      style: 'currency',
      currency: 'USD'
    });

  const totalAmount = stripeInvoices?.reduce((sum, invoice) => sum + invoice.total, 0);

  return (
    <>
      {isCollapsed ? (
        <Box>
          <Typography variant="h5">
            <IconButton size="small" onClick={() => setIsCollapsed(false)} color="primary">
              <ExpandMoreIcon />
            </IconButton>
            {`Total: ${formatAmount(totalAmount)}`}{' '}
          </Typography>
        </Box>
      ) : (
        <>
          <Typography variant="h5">
            <IconButton size="small" onClick={() => setIsCollapsed(true)} color="primary">
              <ExpandLessIcon />
            </IconButton>
            {`Totals: ${formatAmount(totalAmount)}`}{' '}
          </Typography>
          <List dense>
            {stripeInvoices.map((invoice) => (
              <ListItem key={invoice.id} dense>
                <ListItemText
                  primary={
                    <Stack direction="row" justifyContent="space-between" mx={2} sx={{ width: '70%' }}>
                      <Typography textTransform="uppercase" variant="subtitle2">
                        {dayjs.unix(invoice.created).format('MMM DD, YYYY')}
                      </Typography>
                      <Typography>{formatAmount(invoice.total)}</Typography>
                    </Stack>
                  }
                  // secondary={`Paid on ${dayjs.unix(invoice.created).format('MM-DD-YYYY')}`}
                />
              </ListItem>
            ))}
            <Divider />
            {/* <ListItem>
              <ListItemText primary={`Total Paid: ${formatAmount(totalAmount)}`} />
            </ListItem> */}
          </List>
        </>
      )}
    </>
  );
};

StripeInvoiceCell.propTypes = {
  stripeInvoices: PropTypes.array.isRequired
};

const AccrualsDialog = ({ open, setOpen, influencer }) => {
  const [isLoading, setIsLoading] = useState(false);
  const [referredUsers, setReferredUsers] = useState([]);

  const [tabValue, setTabValue] = useState('Premium');
  const [openTransferDialog, setOpenTransferDialog] = useState(false);

  const actionTabOptions = ['Referred Users', 'Manual Payout', 'Payout History', 'Accrual Settings'];
  const [actionTabValue, setActionTabValue] = useState(actionTabOptions[0]);

  const [balance, setBalance] = useState({});
  const [selectedInvoices, setSelectedInvoices] = useState([]);
  const [selectedFirstPaymentInvoices, setSelectedFirstPaymentInvoices] = useState([]);
  const [openPaymentConfirmationDialog, setOpenPaymentConfirmationDialog] = useState(false);

  const referralFeeFirstPayment = selectedFirstPaymentInvoices.reduce((acc, curr) => curr.total + acc, 0) || 0;
  const referralFeeSucceedingBase = useMemo(() => selectedInvoices.reduce((acc, curr) => curr.total + acc, 0) || 0, [selectedInvoices]);

  const referralFeeSucceeding = referralFeeSucceedingBase * 0.2;
  const transactionFees = (referralFeeSucceeding + referralFeeFirstPayment) * 0.05;
  const netPayment = referralFeeSucceeding + referralFeeFirstPayment - transactionFees;

  const getReferredUsers = useCallback(async () => {
    try {
      setIsLoading(true);
      const response = await callAzureFunction({
        url: 'influencer/get-referrals',
        method: 'post',
        data: { user_email: influencer?.email, subscription: tabValue, is_for_admin: true }
      });

      setReferredUsers(response.data);
    } catch (error) {
      console.error(error);
    } finally {
      setIsLoading(false);
    }
  }, [influencer, tabValue]);

  const getAvailableBalance = useCallback(async () => {
    try {
      const response = await callAzureFunction({ url: 'stripe/balance/admin', method: 'get' });

      const { available, pending } = response?.data || {};
      setBalance({ available: available[0]?.amount, pending: pending[0]?.amount });
    } catch (error) {
      enqueueSnackbar({
        variant: 'error',
        message: error.response?.data,
        anchorOrigin: { vertical: 'top', horizontal: 'right' }
      });
    }
  }, []);

  const columns = useMemo(() => {
    const finalColumns = [
      {
        field: 'id',
        headerName: 'ID',
        width: 100,
        align: 'center',
        headerAlign: 'center',
        renderCell: (params) => params.value
      },
      {
        field: 'created_at',
        headerName: 'Date Referred',
        width: 150,
        flex: 0.5,
        renderCell: (params) => dayjs(params.row?.created_at).format('MM-DD-YYYY')
      },
      {
        field: 'email',
        headerName: 'Email Address',
        width: 150,
        flex: 1
      },
      {
        field: 'stripe_invoices',
        headerName: 'Subscription Payments',
        width: 150,
        flex: 1,
        renderCell: (params) => <StripeInvoiceCell stripeInvoices={params.row.stripe_invoices} />
      }
    ];

    return finalColumns;
  }, []);

  const handleClose = () => {
    setSelectedInvoices([]);
    setSelectedFirstPaymentInvoices([]);
    setOpen(false);
  };

  useEffect(() => {
    if (open && actionTabValue === 'Referred Users') getReferredUsers();
  }, [getReferredUsers, open, actionTabValue]);

  useEffect(() => {
    getAvailableBalance();
  }, [getAvailableBalance, actionTabValue]);

  return (
    <Dialog open={open} onClose={handleClose} fullWidth maxWidth="lg" sx={{ zIndex: 100 }}>
      <Tabs
        value={actionTabValue}
        textColor="black"
        indicatorColor="primary"
        onChange={(event, newValue) => {
          setActionTabValue(newValue);
        }}
      >
        {actionTabOptions.map((tab) => (
          <Tab key={tab} label={tab} value={tab} />
        ))}
      </Tabs>
      <DialogContent>
        <Grid container pt={2} pb={5}>
          <Grid item xs={5} direction="row" container>
            <Grid item xs={8}>
              <Typography variant="h5">Influencer</Typography>
              <Typography variant="h5" color="primary">
                {influencer?.email}
              </Typography>
            </Grid>
            <Grid item xs={4}>
              <Typography variant="h5">Joined on</Typography>
              <Typography variant="h5" color="primary">
                {dayjs(influencer?.created_at).format('MM-DD-YYYY')}
              </Typography>
            </Grid>
          </Grid>
          <Grid item xs={2} direction="row" />

          <Grid item xs={5} direction="row" container>
            <Grid item xs={6}>
              <Typography variant="h5" color="primary">
                Accrued Payout
              </Typography>
              <Typography variant="h4">$ 0.00</Typography>
            </Grid>

            <Grid item xs={6} direction="row">
              <Typography variant="h5" color="primary">
                Platform Balance
              </Typography>
              <Typography variant="h4">{formatStripeAmount(balance?.available)}</Typography>
            </Grid>
          </Grid>
        </Grid>

        {actionTabValue === actionTabOptions[0] && (
          <>
            <Box sx={{ mb: 1, width: '100%' }}>
              <Tabs
                value={tabValue}
                onChange={(event, newValue) => {
                  setTabValue(newValue);
                }}
                // textColor="secondary"
                TabIndicatorProps={{
                  style: { backgroundColor: 'primary' }
                }}
                indicatorColor="secondary"
              >
                {tabOptions.map((tab, index) => (
                  <Tab key={index} label={tab} value={tab} />
                ))}
              </Tabs>
            </Box>

            <DataGrid
              rows={referredUsers}
              columns={columns}
              loading={isLoading}
              disableRowSelectionOnClick
              disableColumnFilter
              disableColumnMenu
              autoHeight
              getRowHeight={() => 'auto'}
              getRowId={(row) => row.id}
              localeText={{ noRowsLabel: 'No referred users found' }}
            />
          </>
        )}

        {/* {actionTabValue === actionTabOptions[1] && (
          <>
            <DataGrid
              rows={accruedTransactions}
              columns={stripeColumns}
              loading={isLoading}
              disableRowSelectionOnClick
              disableColumnFilter
              disableColumnMenu
              getRowId={(row) => row.id}
              localeText={{ noRowsLabel: 'No accrued transactions found' }}
            />
          </>
        )} */}

        {actionTabValue === actionTabOptions[1] && (
          <ManualPayment
            influencer={influencer}
            setSelectedInvoices={setSelectedInvoices}
            setSelectedFirstPaymentInvoices={setSelectedFirstPaymentInvoices}
          />
        )}

        {actionTabValue === actionTabOptions[2] && (
          <TransferHistory
            influencer={influencer}
            setSelectedInvoices={setSelectedInvoices}
            setSelectedFirstPaymentInvoices={setSelectedFirstPaymentInvoices}
          />
        )}

        {actionTabValue === actionTabOptions[3] && (
          <>
            <List>
              <ListItem>
                <ListItemIcon>
                  <CheckCircleOutlineIcon />
                </ListItemIcon>
                <ListItemText>
                  100% of the net payment made by the user for the 1st month of subscription goes to the influencer.
                </ListItemText>
              </ListItem>
              <ListItem>
                <ListItemIcon>
                  <CheckCircleOutlineIcon />
                </ListItemIcon>
                <ListItemText>20% of the net payments made by the user for the succeeding months goes to the influencer.</ListItemText>
              </ListItem>
            </List>
          </>
        )}
      </DialogContent>
      {actionTabValue === actionTabOptions[1] && (
        <Grid container px={4}>
          <Grid item xs={4} />
          <Grid item xs={8}>
            <PaymentBreakdown
              referralFeeFirstPayment={referralFeeFirstPayment}
              referralFeeSucceedingBase={referralFeeSucceedingBase}
              referralFeeSucceeding={referralFeeSucceeding}
              transactionFees={transactionFees}
              netPayment={netPayment}
            />
          </Grid>
        </Grid>
      )}

      <DialogActions>
        {actionTabValue === actionTabOptions[1] && (
          <Button variant="contained" disabled={!netPayment || netPayment < 500} onClick={() => setOpenPaymentConfirmationDialog(true)}>
            Make a Manual Payment
          </Button>
        )}
        <Button onClick={handleClose}>Close</Button>
      </DialogActions>
      <PaymentConfirmationDialog
        open={openPaymentConfirmationDialog}
        setOpen={setOpenPaymentConfirmationDialog}
        influencer={influencer}
        invoices={selectedInvoices}
        referralFeeFirstPayment={referralFeeFirstPayment}
        referralFeeSucceedingBase={referralFeeSucceedingBase}
        referralFeeSucceeding={referralFeeSucceeding}
        transactionFees={transactionFees}
        netPayment={netPayment}
      />
      <StripeTransferDialog open={openTransferDialog} setOpen={setOpenTransferDialog} influencer={influencer} />
    </Dialog>
  );
};

AccrualsDialog.propTypes = {
  open: PropTypes.bool,
  setOpen: PropTypes.func,
  influencer: PropTypes.object
};

export default AccrualsDialog;
