import { useState, useCallback, useEffect, useMemo } from 'react';
import PropTypes from 'prop-types';

import callAzureFunction from 'utils/callAzureFunctions';
import { Box, Typography, Stack } from '@mui/material';
import { DataGrid } from '@mui/x-data-grid';
import MenuActionButton from 'ui-components/buttons/MenuActionButton';
import { formatStripeAmount } from 'utils/transaction-helpers';
import dayjs from 'dayjs';
import { enqueueSnackbar } from 'notistack';
import TransferBreakdownDialog from '../support-dialogs/TransferBreakdownDialog';

const TransferHistory = ({ influencer }) => {
  const [isLoading, setIsLoading] = useState(false);
  const [transfers, setTransfers] = useState([]);

  const [selectedTransferId, setSelectedTransferId] = useState(null);
  const [openBreakdownDialog, setOpenBreakdownDialog] = useState(false);

  const getTransfersToInfluencers = useCallback(async () => {
    try {
      setIsLoading(true);
      const response = await callAzureFunction({
        url: 'stripe/transfers-to-influencer',
        method: 'post',
        data: { user_email: influencer?.email }
      });

      const { data } = response?.data || {};

      setTransfers(data);
    } catch (error) {
      enqueueSnackbar({
        variant: 'error',
        message: 'Error getting available balance',
        anchorOrigin: { vertical: 'top', horizontal: 'right' }
      });
    } finally {
      setIsLoading(false);
    }
  }, [influencer]);

  const columns = useMemo(
    () => [
      {
        field: 'action',
        headerName: 'Action',
        width: 75,
        align: 'center',
        sortable: false,
        headerAlign: 'center',
        // eslint-disable-next-line arrow-body-style
        renderCell: (params) => {
          return (
            <>
              <MenuActionButton
                menuItems={[
                  {
                    content: (
                      <Stack direction="row" gap={2} alignItems="center">
                        {/* <IconLayersSubtract /> */}
                        View Breakdown
                      </Stack>
                    ),
                    onClick: () => {
                      setSelectedTransferId(params.row.id);
                      setOpenBreakdownDialog(true);
                    }
                  }
                ]}
              />
            </>
          );
        }
      },
      {
        field: 'id',
        headerName: 'Transaction ID',
        width: 250,
        headerAlign: 'center'
      },
      {
        field: 'created',
        headerName: 'Date Paid',
        width: 150,
        flex: 1,
        renderCell: (params) => dayjs.unix(params.value).format('MMM DD, YYYY hh:mma')
      },
      {
        field: 'custom',
        headerName: 'Type',
        width: 50,
        flex: 0.5,
        renderCell: (params) => (
          <Typography textTransform="capitalize" alignItems="center" display="flex" height="100%">
            {params.row.metadata?.manner_of_transfer || 'Manual'}
          </Typography>
        )
      },
      {
        field: 'transacted_by',
        headerName: 'Transacted By',
        width: 150,
        flex: 1,
        renderCell: (params) => (
          <Typography alignItems="center" display="flex" height="100%">
            {params.row.metadata?.transacted_by || 'N/A'}
          </Typography>
        )
      },
      {
        field: 'amount',
        headerName: 'Amount Paid',
        width: 100,
        flex: 0.75,
        renderCell: (params) => formatStripeAmount(params.value)
      }
    ],
    []
  );

  useEffect(() => {
    getTransfersToInfluencers();
  }, [getTransfersToInfluencers]);

  return (
    <>
      <Box px={3}>
        <DataGrid
          rows={transfers}
          columns={columns}
          loading={isLoading}
          disableRowSelectionOnClick
          disableColumnFilter
          disableColumnMenu
          getRowId={(row) => row.id}
          localeText={{ noRowsLabel: 'No payouts found', noResultsOverlayLabel: 'No results found' }}
          hideFooterSelectedRowCount
          autoHeight
          slotProps={{
            loadingOverlay: {
              variant: 'linear-progress',
              noRowsVariant: 'skeleton'
            }
          }}
          // paginationModel={{ pageSize: 10, page: 0 }}
          density="compact"
        />
      </Box>
      <TransferBreakdownDialog transferId={selectedTransferId} open={openBreakdownDialog} setOpen={setOpenBreakdownDialog} />
    </>
  );
};

TransferHistory.propTypes = {
  influencer: PropTypes.object
};
export default TransferHistory;
