import { useEffect, useCallback, useState, useMemo } from 'react';

import { Container, Typography, Grid, Card, IconButton, Tooltip, LinearProgress, Stack, Tabs, Tab } from '@mui/material';

import DoneOutlineIcon from '@mui/icons-material/DoneOutline';
import WorkspacePremiumIcon from '@mui/icons-material/WorkspacePremium';
import StarIcon from '@mui/icons-material/Star';
import StarHalfIcon from '@mui/icons-material/StarHalf';
import HelpIcon from '@mui/icons-material/Help';

// import ThumbDownIcon from '@mui/icons-material/ThumbDown';
import ConnectWithoutContactIcon from '@mui/icons-material/ConnectWithoutContact';

import AppWidgetSummary from 'ui-components/cards/AppWidgetSummary';
import ApprovalDialog from 'ui-components/dialogs/ApprovalDialog';

import { DataGrid } from '@mui/x-data-grid/DataGrid';
import callAzureFunction from 'utils/callAzureFunctions';
import MenuActionButton from 'ui-components/buttons/MenuActionButton';
import AccrualsDialog from './components/AccrualsDialog';
import InfluencerStripeOnboardingStatusDialog from './components/InfluencerStripeOnboardingStatusDialog';

const SuperAdminPanel = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [isLoadingMetrics, setIsLoadingMetrics] = useState(false);
  const [activeTab, setActiveTab] = useState(0);
  // const [selectedUserOnboardingData, setSelectedUserOnboardingData] = useState({});
  const [openOnboardingDataDialog, setOpenOnboardingDataDialog] = useState(false);

  const [userMetrics, setUserMetrics] = useState({});
  const { free, basic, premium } = userMetrics;

  const [influencerRows, setInfluencerRows] = useState([]);
  const [filteredInfluencerRows, setFilteredInfluencerRows] = useState([]);

  const activeInfluencersCount = influencerRows.filter((influencer) => influencer.status === 'active').length;

  const [userId, setUserId] = useState(null);
  const [isApproved, setIsApproved] = useState(false);

  const [influencer, setInfluencer] = useState(null);
  const [openAccrualsDialog, setOpenAccrualsDialog] = useState(false);

  const [openApprovalDialog, setOpenApprovalDialog] = useState(false);

  const tabOptions = [
    {
      label: 'Pending'
      // icon: <TravelExploreIcon />
    },
    {
      label: 'Approved'
      // icon: <AirplaneTicketIcon />
    }
  ];

  const getAllInfluencers = useCallback(async () => {
    try {
      setIsLoading(true);

      const response = await callAzureFunction({ url: 'users/influencers', method: 'get', params: { user_type: 'influencer' } });

      const influencers = response?.data;

      setInfluencerRows(influencers || []);
    } catch (error) {
      console.log(error);
    } finally {
      setIsLoading(false);
    }
  }, []);

  const getUserMetricsByMembershipPlan = useCallback(async () => {
    try {
      setIsLoadingMetrics(true);

      const { free, basic, premium } = {};

      setUserMetrics({ free, basic, premium });
    } catch (error) {
      console.log(error);
    } finally {
      setIsLoadingMetrics(false);
    }
  }, []);

  const handleApproval = useCallback(async () => {
    try {
      setIsLoading(true);
      setOpenApprovalDialog(false);

      await callAzureFunction({
        url: 'influencer/approve',
        method: 'post',
        data: {
          is_approved: isApproved,
          user_id: userId
        }
      });

      await getAllInfluencers();
    } catch (error) {
      console.log(error);
    } finally {
      setIsLoading(false);
    }
  }, [userId, isApproved, getAllInfluencers]);

  useEffect(() => {
    getAllInfluencers();
    getUserMetricsByMembershipPlan();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handlePreapproval = (id, approvalState) => {
    setUserId(id);
    setIsApproved(approvalState);
    setOpenApprovalDialog(true);
  };

  const columns = useMemo(
    () => [
      {
        field: 'action',
        headerName: 'Action',
        width: 100,
        align: 'center',
        sortable: false,
        headerAlign: 'center',
        renderCell: (params) => {
          const { is_approved: isApproved, id, is_connect_ready: isConnectReady } = params.row;

          return (
            <>
              {!isApproved && (
                <>
                  {isConnectReady ? (
                    <IconButton onClick={() => handlePreapproval(id, true)} disabled={isLoading}>
                      <Tooltip title="Approve this account">
                        <DoneOutlineIcon color="success" />
                      </Tooltip>
                    </IconButton>
                  ) : (
                    <IconButton
                      onClick={() => {
                        setOpenOnboardingDataDialog(true);
                        setInfluencer(params.row);
                      }}
                    >
                      <Tooltip title="This account has not yet completed the Stripe onboarding process">
                        <HelpIcon color="info" />
                      </Tooltip>
                    </IconButton>
                  )}
                </>
              )}

              {isApproved && (
                <>
                  <MenuActionButton
                    menuItems={[
                      {
                        content: (
                          <Stack direction="row" gap={2} alignItems="center">
                            {/* <IconLayersSubtract /> */}
                            View Accrued Fees
                          </Stack>
                        ),
                        onClick: () => {
                          setOpenAccrualsDialog(true);
                          setInfluencer(params.row);
                        }
                      }
                    ]}
                  />
                </>
              )}
            </>
          );
        }
      },
      {
        field: 'id',
        headerName: 'ID',
        width: 100,
        align: 'center',
        headerAlign: 'center'
      },
      { field: 'email', headerName: 'Email Address', width: 150, flex: 1.5 },
      {
        field: 'display_name',
        headerName: 'User Name',
        width: 200,
        flex: 1,
        renderCell: (params) => {
          const { email } = params.row;
          const displayName = email.split('@')[0];

          return <>{displayName}</>;
        }
      },
      {
        field: 'accrued_fee',
        headerName: 'Accrued Fee',
        width: 150,
        flex: 1,
        renderCell: (params) => (
          <Typography sx={{ textTransform: 'capitalize', justifyItems: 'center', mt: 2 }}>{params.value ?? 'N/A'}</Typography>
        )
      },
      {
        field: 'is_approved',
        headerName: 'Status',
        width: 150,
        flex: 1,
        renderCell: (params) => (
          <Typography sx={{ textTransform: 'capitalize', justifyItems: 'center', mt: 2 }}>
            {params.value === true ? 'Approved' : 'Pending'}
          </Typography>
        )
      }
    ],
    [isLoading]
  );

  useEffect(() => {
    if (activeTab === 0) {
      setFilteredInfluencerRows(() => influencerRows.filter((influencer) => !influencer.is_approved));
    } else {
      setFilteredInfluencerRows(() => influencerRows.filter((influencer) => influencer.is_approved === true));
    }
  }, [activeTab, influencerRows]);

  return (
    <>
      <LinearProgress sx={{ mb: 0.3 }} variant={isLoading || isLoadingMetrics ? 'indeterminate' : 'determinate'} value={100} />

      <Card sx={{ bgcolor: 'primary.main' }}>
        <Container maxWidth="xl">
          <Typography variant="h3" sx={{ my: 5 }} color="white !important">
            Hi, Welcome back 👋
          </Typography>

          <Typography variant="h4" sx={{ my: 3 }} color="white !important">
            User Metrics
          </Typography>

          <Grid container spacing={3} mb={3}>
            <Grid item xs={12} md={3}>
              <AppWidgetSummary
                title="Premium Users"
                total={premium || 'TBD'}
                color="warning"
                isLoading={isLoadingMetrics}
                icon={<WorkspacePremiumIcon sx={{ color: 'primary.main', fontSize: 48 }} />}
              />
            </Grid>

            <Grid item xs={12} md={3}>
              <AppWidgetSummary
                title="Basic Users"
                total={basic || 'TBD'}
                color="info"
                isLoading={isLoadingMetrics}
                icon={<StarIcon sx={{ color: 'secondary.main', fontSize: 48 }} />}
              />
            </Grid>

            <Grid item xs={12} md={3}>
              <AppWidgetSummary
                title="Free Users"
                total={free || 'TBD'}
                color="success"
                isLoading={isLoadingMetrics}
                icon={<StarHalfIcon sx={{ color: '#6495ED !important', fontSize: 48 }} />}
              />
            </Grid>

            <Grid item xs={12} md={3}>
              <AppWidgetSummary
                title="Active Influencers"
                isLoading={isLoading}
                total={activeInfluencersCount}
                color="success"
                icon={<ConnectWithoutContactIcon sx={{ color: 'purple', fontSize: 48 }} />}
              />
            </Grid>

            <Grid item xs={12}>
              <Typography variant="h4" sx={{ my: 3 }} color="white !important">
                Influencers
              </Typography>
              <Tabs
                sx={{
                  borderTopLeftRadius: 3,
                  border: 1,
                  // borderColor: 'primary',
                  pb: 0.5
                }}
                value={activeTab}
                onChange={(_, newValue) => setActiveTab(newValue)}
              >
                {tabOptions.map((tab, index) => (
                  <Tab
                    key={index}
                    value={index}
                    label={<Typography color="white !important">{tab.label}</Typography>}
                    iconPosition="start"
                  />
                ))}
              </Tabs>

              <div>
                <DataGrid
                  autoHeight
                  rows={filteredInfluencerRows}
                  columns={columns}
                  loading={isLoading}
                  disableRowSelectionOnClick
                  disableColumnFilter
                  disableColumnMenu
                />
              </div>
            </Grid>
          </Grid>
        </Container>

        <ApprovalDialog open={openApprovalDialog} setOpen={setOpenApprovalDialog} handleApproval={handleApproval} isApproved={isApproved} />
        <AccrualsDialog open={openAccrualsDialog} setOpen={setOpenAccrualsDialog} influencer={influencer} />
        <InfluencerStripeOnboardingStatusDialog
          open={openOnboardingDataDialog}
          setOpen={setOpenOnboardingDataDialog}
          influencer={influencer}
        />
      </Card>
    </>
  );
};

export default SuperAdminPanel;
