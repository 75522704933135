const handleError = (error) => {
  let errorMessage = 'Oops, something went wrong.';

  if (error?.response) {
    // The request was made and the server responded with a status code
    // that falls out of the range of 2xx
    const { data, status } = error.response;
    console.error(data, status);

    if (typeof data === 'string') {
      errorMessage = data;
    }

    if (typeof data?.error === 'string') {
      errorMessage = data.error;
    }
    if (typeof data?.error?.message === 'string') {
      errorMessage = data.error.message;
    }
  } else if (error?.request) {
    // The request was made but no response was received
    // `error.request` is an instance of XMLHttpRequest in the browser and an instance of
    // http.ClientRequest in node.js
    console.error(error.request);
  } else if (error?.message) {
    // Regular error
    console.error('Error', error.message);

    if (typeof error.message === 'string') {
      errorMessage = error.message;
    }
  }

  return errorMessage;
};

export default handleError;
