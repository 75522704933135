import axios from 'axios';
import Cookies from 'universal-cookie';
import { jwtDecode } from 'jwt-decode';
import { getCookieNameSuffix } from './cookies';
import { callAzureFuncPublic } from './call-azure-function-auth';

import dayjs from 'dayjs';

import clearReduxStore from './clear-redux-store';
import { store } from '../store';
import { setLoggedOut } from 'store/features/userSlice';
// import { enqueueSnackbar } from 'notistack';

const functionConfig = {
  baseURL: import.meta.env.VITE_APP_AZ_FUNCTIONS_BASE_URI,
  withCredentials: true
};

const refreshAccessToken = async (accessToken) => {
  try {
    if (accessToken) {
      // decode token
      const decoded = jwtDecode(accessToken);

      if (decoded?.exp) {
        const expDate = new Date(decoded.exp * 1000);

        // check if token is close to expiration
        if (dayjs(expDate).diff(dayjs(), 'minute') <= 1) {
          // refresh the token
          await callAzureFuncPublic({
            url: `/auth-refresh-access`,
            method: 'get'
          });
        }
      }
    } else {
      throw new Error('No access token');
    }
  } catch (error) {
    store.dispatch(setLoggedOut());
    // clear redux store values
    clearReduxStore();
    // go to login page
    window.location.assign('/login');
    await callAzureFuncPublic({
      url: `/logout`,
      method: 'post'
    });
  }
};

const callAzureFunction = async (requestConfig) => {
  const cookies = new Cookies(null, { path: '/' });

  // get cookie name suffix
  const cookieNameSuffix = getCookieNameSuffix();

  // get readable access token for functions
  const accessToken = cookies.get(`AF_ATHP${cookieNameSuffix}`);

  await refreshAccessToken(accessToken);

  const axiosResponse = await axios({
    ...requestConfig,
    ...functionConfig
  });

  return axiosResponse;
};

export default callAzureFunction;
