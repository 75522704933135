import { createSlice } from '@reduxjs/toolkit';

export const initialState = {
  data: {},
  isLoggedIn: false,
  permissions: []
};

const extractPermissions = (action) => {
  const userPermissions = new Set();
  action.payload?.data?.AccessRoles?.forEach((accessRole) => {
    const permissions = JSON.parse(accessRole.permissions);
    permissions.forEach((permission) => {
      userPermissions.add(permission);
    });
  });
  return Array.from(userPermissions);
};

const userSlice = createSlice({
  name: 'user',
  initialState,
  reducers: {
    setUserData: (state, action) => {
      const { data, isLoggedIn } = action.payload;

      state.data = { ...state.data, ...data };
      state.isLoggedIn = isLoggedIn || state.isLoggedIn;
      state.permissions = extractPermissions(action);
    },
    setLoggedIn: (state, action) => {
      state.data = action.payload.data;
      state.isLoggedIn = true;
      state.permissions = extractPermissions(action);
    },
    setLoggedOut: (state) => {
      state.data = {};
      state.isLoggedIn = false;
      state.permissions = [];
    }
  }
});

// Action creators are generated for each case reducer function
export const { setUserData, setLoggedIn, setLoggedOut } = userSlice.actions;

export default userSlice.reducer;
