import './index.css';
import 'assets/scss/style.scss';

import { GoogleOAuthProvider } from '@react-oauth/google';
import { ThemeProvider } from '@mui/material/styles';
import { CssBaseline } from '@mui/material';
import themes from './themes';

import { SnackbarProvider } from 'notistack';

import Routes from './routes';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';

import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';

const stripePromise = loadStripe(import.meta.env.VITE_APP_STRIPE_PUBLISHABLE_KEY);

const stripeElementOptions = {
  appearance: {
    theme: 'night',
    variables: {
      fontFamily: 'Sohne, system-ui, sans-serif',
      fontWeightNormal: '500',
      borderRadius: '8px',
      colorBackground: '#0A2540',
      colorPrimary: '#EFC078',
      accessibleColorOnColorPrimary: '#1A1B25',
      colorText: 'white',
      colorTextSecondary: 'white',
      colorTextPlaceholder: '#ABB2BF',
      tabIconColor: 'white',
      logoColor: 'dark'
    },
    rules: {
      '.Input': {
        backgroundColor: '#212D63',
        border: '1px solid var(--colorPrimary)'
      }
    },
    layout: {
      type: 'tabs',
      defaultCollapsed: false
    }
  }
};

// eslint-disable-next-line arrow-body-style
const App = () => {
  return (
    <ThemeProvider theme={themes()}>
      <GoogleOAuthProvider clientId={import.meta.env.VITE_APP_AUTH_GOOGLE_CLIENT_ID}>
        <LocalizationProvider dateAdapter={AdapterDayjs}>
          <CssBaseline />
          <SnackbarProvider maxSnack={3}>
            <Elements stripe={stripePromise} options={stripeElementOptions}>
              <Routes />
            </Elements>
          </SnackbarProvider>
        </LocalizationProvider>
      </GoogleOAuthProvider>
    </ThemeProvider>
  );
};

export default App;
