import { Dialog, DialogTitle, DialogActions, DialogContent, Button, Grid, Typography } from '@mui/material';
import dayjs from 'dayjs';
import PropTypes from 'prop-types';

const InfluencerStripeOnboardingStatusDialog = ({ open, setOpen, influencer }) => {
  const {
    stripe_onboarding_status: stripeOnboardingStatus,
    email,
    stripe_account_id: stripeAccountId,
    created_at: createdAt
  } = influencer || {};

  return (
    <Dialog open={open} fullWidth maxWidth="sm">
      <DialogTitle variant="h4">Stripe Onboarding Status</DialogTitle>
      <DialogContent>
        <Grid container spacing={1}>
          <Grid item xs={12} my={0.5}>
            <Typography variant="h5">Basic Data</Typography>
          </Grid>
          <Grid item xs={6}>
            Email
          </Grid>
          <Grid item xs={6}>
            {email}
          </Grid>
          <Grid item xs={6}>
            Account Created
          </Grid>
          <Grid item xs={6}>
            {dayjs(createdAt).format('MMM DD, YYYY')}
          </Grid>

          {stripeAccountId ? (
            <>
              <Grid item xs={12} mb={0.5} mt={2}>
                <Typography variant="h5">Stripe Connect Status Information</Typography>
              </Grid>
              <Grid item xs={6}>
                Full Name
              </Grid>
              <Grid item xs={6}>
                {stripeOnboardingStatus?.full_name || 'Not yet provided'}
              </Grid>
              <Grid item xs={6}>
                Business Name
              </Grid>
              <Grid item xs={6}>
                {stripeOnboardingStatus?.business_name || 'Not yet provided'}
              </Grid>
              <Grid item xs={6}>
                Details Submitted
              </Grid>
              <Grid item xs={6}>
                {stripeOnboardingStatus?.charges_enabled ? 'Yes' : 'No'}
              </Grid>
              <Grid item xs={6}>
                Payouts Enabled
              </Grid>
              <Grid item xs={6}>
                {stripeOnboardingStatus?.payouts_enabled ? 'Yes' : 'No'}
              </Grid>
              <Grid item xs={6}>
                Charges Enabled
              </Grid>
              <Grid item xs={6}>
                {stripeOnboardingStatus?.details_submitted ? 'Yes' : 'No'}
              </Grid>
            </>
          ) : (
            <>
              <Grid item xs={6}>
                Stripe Connect
              </Grid>
              <Grid item xs={6}>
                N/A. Onboarding not yet started
              </Grid>
            </>
          )}
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button onClick={() => setOpen(false)}>Close</Button>
      </DialogActions>
    </Dialog>
  );
};

InfluencerStripeOnboardingStatusDialog.propTypes = {
  open: PropTypes.bool,
  setOpen: PropTypes.func,
  influencer: PropTypes.object
};

export default InfluencerStripeOnboardingStatusDialog;
