// import React, { useState } from "react";

import PropTypes from 'prop-types';

import { Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Button } from '@mui/material';

const ApprovalDialog = ({ open, setOpen, isApproved, handleApproval }) => {
  // const
  const approved = {
    title: 'Approval',
    message: 'Are you sure you want to approve this influencer account?'
  };

  const rejected = {
    title: 'Rejection',
    message: 'Are you sure you want to reject this influencer account?'
  };

  const dialogContent = isApproved ? approved : rejected;

  return (
    <Dialog open={open} onClose={() => setOpen(false)}>
      <DialogTitle variant="h3">Confirm {dialogContent.title}</DialogTitle>
      <DialogContent>
        <DialogContentText variant="h4">{dialogContent.message}</DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={() => setOpen(false)}>Cancel</Button>
        <Button onClick={handleApproval} color="primary" autoFocus variant="contained">
          Confirm
        </Button>
      </DialogActions>
    </Dialog>
  );
};

ApprovalDialog.propTypes = {
  open: PropTypes.bool,
  setOpen: PropTypes.func,
  isApproved: PropTypes.bool,
  handleApproval: PropTypes.func
};

export default ApprovalDialog;
