import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';

// material-ui
import { Typography, Box, Divider } from '@mui/material';

import {
  AccountCircle as AccountCircleIcon,
  CircleNotifications as CircleNotificationsIcon,
  AirplaneTicket as AirplaneTicketIcon,
  TravelExplore as TravelExploreIcon,
  PersonAddAlt1 as PersonAddAlt1Icon,
  Loyalty as LoyaltyIcon,
  Logout as LogoutIcon,
  Analytics as AnalyticsIcon,
  AutoGraph as AutoGraphIcon,
  Dashboard as DashboardIcon,
  Payment as PaymentIcon,
  Storefront as StoreFrontIcon
} from '@mui/icons-material';

// project imports
import NavGroup from './NavGroup';

// ==============================|| SIDEBAR MENU LIST ||============================== //

const MenuList = ({ drawerOpen }) => {
  // const { leftDrawerOpened: opened } = useContext(DrawerContext);
  const userData = useSelector((state) => state.user?.data);

  const userType = userData?.userType || 'user';

  const appMenuOptions = [
    {
      id: 'apps',
      title: 'My Apps',
      type: 'item',
      url: '/my-apps',
      icon: DashboardIcon
    },
    {
      id: 'notification-settings',
      title: 'Notification Settings',
      type: 'item',
      url: '/notification-settings',
      icon: CircleNotificationsIcon
    },
    {
      id: 'flight-deal-scanner',
      title: 'Flight Deal Scanner',
      type: 'item',
      url: '/flight-deal-scanner',
      icon: AirplaneTicketIcon,
      breadcrumbs: false
    },
    {
      id: 'points-scanner',
      title: 'Points Deal Scanner',
      type: 'item',
      url: '/points',
      icon: LoyaltyIcon,
      breadcrumbs: false
    },
    {
      id: 'trip-planner-ai',
      title: 'Trip Planner AI',
      type: 'item',
      url: '/itinerary-ai',
      icon: TravelExploreIcon
    }
  ];

  const userMenuOptions = [
    {
      id: 'profile',
      title: 'My Profile',
      type: 'item',
      url: '/profile',
      icon: AccountCircleIcon
    },
    {
      id: 'subscription',
      title: 'My Subscription',
      type: 'item',
      url: '/subscription',
      icon: PaymentIcon
    },
    {
      id: 'referrals',
      title: 'Refer a Friend',
      type: 'item',
      url: '/referral',
      icon: PersonAddAlt1Icon
    },
    {
      id: 'logout',
      title: 'Log Out',
      type: 'item',
      url: null,
      icon: LogoutIcon
    }
  ];

  const influencerMenuOptions = [
    {
      id: 'influencer',
      title: 'My Referrals',
      type: 'item',
      url: '/influencer/referrals',
      icon: AnalyticsIcon
    },
    {
      id: 'analytics',
      title: 'Analytics',
      type: 'item',
      url: '/influencer/analytics',
      icon: AutoGraphIcon
    },
    {
      id: 'invite-user',
      title: 'Add Referrals',
      type: 'item',
      url: '/influencer/invite-a-user',
      icon: PersonAddAlt1Icon
    }
  ];

  const stripeMenuOptions = [
    {
      id: 'stripe',
      title: 'Stripe Connect',
      type: 'item',
      url: '/influencer/stripe-connect-onboarding',
      icon: StoreFrontIcon
    },
    {
      id: 'balances',
      title: 'Stripe Connect Balances',
      type: 'item',
      url: '/influencer/stripe-connect/balances',
      icon: StoreFrontIcon
    }
  ];

  const superAdminOptions = [
    {
      id: 'super-admin-panel',
      title: 'Admin Panel',
      type: 'item',
      url: 'admin/super-admin',
      icon: StoreFrontIcon
    }
  ];

  const appMenuItems = [
    {
      id: 'app-features',
      type: 'group',
      children: [...appMenuOptions]
    }
  ];

  const basicMenuItems = [
    {
      id: 'core-features',
      type: 'group',
      children: [...userMenuOptions]
    }
  ];

  /**
   *  Influencer menu items
   */

  const influencerStripeMenuItems = [
    {
      id: 'stripe-features',
      type: 'group',
      children: userData.isApproved ? [...stripeMenuOptions] : [...stripeMenuOptions.filter((item) => item.id !== 'balances')]
    }
  ];

  const influencerMenuItems = [
    {
      id: 'influencer',
      type: 'group',
      children: [...influencerMenuOptions]
    }
  ];

  const superAdminMenuItems = [
    {
      id: 'super-admin',
      type: 'group',
      children: [...superAdminOptions]
    }
  ];

  const logoutMenuItem = [
    {
      id: 'log-out',
      type: 'group',
      children: [
        {
          id: 'logout',
          title: 'Log Out',
          type: 'item',
          url: '/',
          icon: LogoutIcon
        }
      ]
    }
  ];

  const menuItemsMappedByUserType = {
    user: [...appMenuItems, ...basicMenuItems],
    member: [...appMenuItems, ...basicMenuItems],
    influencer: userData.isApproved
      ? [...influencerMenuItems, ...influencerStripeMenuItems, ...appMenuItems, ...logoutMenuItem]
      : [...influencerStripeMenuItems, ...logoutMenuItem],
    admin: [...superAdminMenuItems, ...appMenuItems, ...logoutMenuItem]
  };

  const navItems = menuItemsMappedByUserType[userType].map((item) => {
    switch (item.type) {
      case 'group':
        return <NavGroup key={item.id} item={item} opened={drawerOpen} />;
      default:
        return (
          <Typography key={item.id} variant="h6" color="error" align="center">
            Menu Items Error
          </Typography>
        );
    }
  });

  return (
    <Box mt={12}>
      <Divider sx={{ my: 2 }} />
      {navItems}
    </Box>
  );
};

MenuList.propTypes = {
  drawerOpen: PropTypes.bool
};

export default MenuList;
