import {
  MonetizationOn,
  EmojiPeople,
  Groups3,
  Diversity1,
  Wc,
  Surfing,
  LocationCity,
  NaturePeople,
  DinnerDining,
  Nightlife,
  LocalMall
} from '@mui/icons-material';
import dayjs from 'dayjs';

const flightTypes = {
  OneWayTrip: 'one-way-trip',
  RoundTrip: 'round-trip'
};

const sampleOutput = [
  {
    day: 1,
    places: [
      {
        name_of_place: 'Victoria Peak',
        place_description:
          'The highest point on Hong Kong Island, offering a stunning panoramic view of the city skyline, Victoria Harbour, and surrounding islands.',
        reason_why_it_is_recommended: "It's a must-see for first-time visitors to get a comprehensive view of the city.",
        expected_budget: '$50 (including Peak Tram ride and Sky Terrace 428 entrance fee)'
      },
      {
        name_of_place: 'Central District',
        place_description:
          'The bustling financial and commercial center of Hong Kong, filled with skyscrapers, historic sites, and shopping malls.',
        reason_why_it_is_recommended:
          'Experience the contrast of modern architecture and colonial history while exploring the heart of Hong Kong.',
        expected_budget: '$0 (walking around and sightseeing)'
      },
      {
        name_of_place: 'Star Ferry Ride',
        place_description: "A ferry service across Victoria Harbour, offering iconic views of Hong Kong's skyline.",
        reason_why_it_is_recommended: "An affordable way to experience Hong Kong's harbor and enjoy the cityscape from the water.",
        expected_budget: '$5 (for a round-trip ride)'
      },
      {
        name_of_place: 'Temple Street Night Market',
        place_description: 'A vibrant night market in Kowloon filled with street food, souvenirs, and local vendors.',
        reason_why_it_is_recommended: 'Perfect for experiencing local culture, food, and finding unique souvenirs.',
        expected_budget: '$30 (for food and shopping)'
      }
    ]
  },
  {
    day: 2,
    places: [
      {
        name_of_place: 'Tsim Sha Tsui Promenade',
        place_description: 'A scenic waterfront promenade that offers stunning views of the Hong Kong Island skyline and Victoria Harbour.',
        reason_why_it_is_recommended:
          'Great for a morning walk, and it leads to several cultural attractions like the Hong Kong Space Museum and the Avenue of Stars.',
        expected_budget: '$0 (walking around)'
      },
      {
        name_of_place: 'Avenue of Stars',
        place_description:
          'A promenade along the Victoria Harbour waterfront in Tsim Sha Tsui, honoring the stars of the Hong Kong film industry.',
        reason_why_it_is_recommended: "It's like the Hollywood Walk of Fame, but with a unique Hong Kong twist.",
        expected_budget: '$0 (walking around)'
      },
      {
        name_of_place: 'Hong Kong Museum of History',
        place_description:
          "A museum that offers a comprehensive overview of Hong Kong's history, from its ancient beginnings to modern times.",
        reason_why_it_is_recommended: "It's an excellent way to learn about Hong Kong's rich cultural heritage and history.",
        expected_budget: '$10 (museum entrance fee)'
      },
      {
        name_of_place: 'Kowloon Walled City Park',
        place_description:
          'A historical park on the site of the former Kowloon Walled City, once the most densely populated place on Earth.',
        reason_why_it_is_recommended: 'The park is a peaceful retreat with beautiful gardens and historical displays.',
        expected_budget: '$0 (park entrance is free)'
      },
      {
        name_of_place: 'Mong Kok',
        place_description: 'A lively district known for its shopping, street markets, and vibrant street life.',
        reason_why_it_is_recommended:
          "Explore the bustling markets and experience the energetic atmosphere of one of Hong Kong's most dynamic neighborhoods.",
        expected_budget: '$40 (shopping and snacks)'
      }
    ]
  },
  {
    day: 3,
    places: [
      {
        name_of_place: 'Ngong Ping 360 and Tian Tan Buddha',
        place_description:
          'A cable car ride offering panoramic views leading to the Tian Tan Buddha and Po Lin Monastery on Lantau Island.',
        reason_why_it_is_recommended: "It's a serene escape from the city, offering spiritual insights and breathtaking scenery.",
        expected_budget: '$60 (Ngong Ping 360 round trip and entrance to the Buddha)'
      },
      {
        name_of_place: 'Lantau Island Beaches',
        place_description: "Home to some of Hong Kong's most beautiful beaches, perfect for relaxation.",
        reason_why_it_is_recommended: 'A great way to unwind and enjoy the natural beauty of Hong Kong away from the urban hustle.',
        expected_budget: '$0 (walking around)'
      },
      {
        name_of_place: 'Tai O Fishing Village',
        place_description: 'A traditional fishing village on Lantau Island, known for its stilt houses and cultural heritage.',
        reason_why_it_is_recommended: "A glimpse into Hong Kong's past and a contrast to the modern city life.",
        expected_budget: '$20 (for snacks and souvenirs)'
      },
      {
        name_of_place: 'SoHo',
        place_description: 'A trendy district in Central known for its art galleries, restaurants, and nightlife.',
        reason_why_it_is_recommended: 'A perfect spot to wrap up your trip with a night out or a nice dinner.',
        expected_budget: '$60 (dinner and drinks)'
      }
    ]
  }
];

const budgetOptions = [
  {
    value: 'low',
    title: 'Low',
    label: '0 - 1000 USD',
    icon: <MonetizationOn />
  },
  {
    value: 'moderate',
    title: 'Moderate',
    label: '1000 - 2500 USD',
    icon: <MonetizationOn />
  },
  {
    value: 'high',
    title: 'High',
    label: '2500+ USD',
    icon: <MonetizationOn />
  }
];

const companionOptions = [
  {
    value: 'solo',
    title: 'Solo Travel',
    label: 'Explore on your own for a personal adventure',
    icon: <EmojiPeople />
  },
  {
    value: 'couple',
    title: "Couple's Getaway",
    label: 'Enjoy a romantic trip with your partner',
    icon: <Wc />
  },
  {
    value: 'family',
    title: 'Family Trip',
    label: 'Create memories with the whole family',
    icon: <Diversity1 />
  },
  {
    value: 'friends',
    title: "Friends' Outing",
    label: 'Have fun and bond with your friends',
    icon: <Groups3 />
  }
];

const activityOptions = [
  {
    value: 'beaches',
    title: 'Beaches',
    label: 'Relax on sandy shores and enjoy the ocean breeze',
    icon: <Surfing />
  },
  {
    value: 'citySightseeing',
    title: 'City Sightseeing',
    label: 'Explore urban landmarks and cultural sites',
    icon: <LocationCity />
  },
  {
    value: 'outdoorAdventures',
    title: 'Outdoor Adventures',
    label: 'Engage in thrilling activities in nature',
    icon: <NaturePeople />
  },
  {
    value: 'foodTrip',
    title: 'Food Trip',
    label: 'Discover local cuisines and dining experiences',
    icon: <DinnerDining />
  },
  {
    value: 'nightLife',
    title: 'Nightlife',
    label: 'Experience the vibrant nightlife scene',
    icon: <Nightlife />
  },
  {
    value: 'shopping',
    title: 'Shopping',
    label: 'Shop for local products and souvenirs',
    icon: <LocalMall />
  }
];

const mileagePrograms = {
  eurobonus: {
    name: 'SAS EuroBonuss',
    logoURL: 'https://driptours.com/wp-content/uploads/2024/11/eurobonus.png',
    composeURL: () =>
      `https://book.virginaustralia.com/dx/VADX/#/flight-selection?journeyType=one-way&activeMonth=11-14-2024&awardBooking=true&class=First&ADT=1&CHD=0&INF=0&origin=LAX&destination=SFO&date=11-14-2024&promoCode=&utm_source=seatsaero`
  },
  virginatlantic: {
    name: 'Virgin Atlantic Flying Club',
    logoURL: 'https://driptours.com/wp-content/uploads/2024/11/virginatlantic.png',
    composeURL: ({ from, to, date }) =>
      // 11/13/2024
      `https://www.virginatlantic.com/flight-search/search?action=findFlights&searchByCabin=true&deltaOnlySearch=false&deltaOnly=off&go=Find%20Flights&tripType=ONE_WAY&passengerInfo=ADT:1%7cGBE:0%7cCNN:0%7cINF:0&priceSchedule=price&awardTravel=true&originCity=${from}&destinationCity=${to}&departureDate=${dayjs(date).format('MM/DD/YYYY')}&returnDate=&forceMiles=true`
  },
  aeromexico: {
    name: 'Aeromexico Club Premier',
    logoURL: 'https://driptours.com/wp-content/uploads/2024/11/aeromexico.png',
    composeURL: ({ from, to, date }) =>
      `https://www.aa.com/booking/search?type=OneWay&searchType=Award&from=${from}&to=${to}&pax=1&cabin=&locale=en_US&nearbyAirports=true&depart=${date}&carriers=ALL&pos=US&adult=1`
  },
  american: {
    name: 'American SkyMiles',
    logoURL: 'https://driptours.com/wp-content/uploads/2024/11/american.png',
    composeURL: ({ from, to, date }) =>
      `https://www.aa.com/booking/search?type=OneWay&searchType=Award&from=${from}&to=${to}&pax=1&cabin=&locale=en_US&nearbyAirports=true&depart=${date}&carriers=ALL&pos=US&adult=1`
  },
  delta: {
    name: 'Delta SkyMiles',
    logoURL: 'https://driptours.com/wp-content/uploads/2024/11/delta.png',
    composeURL: ({ from, to, date }) =>
      `https://www.delta.com/flightsearch/search?action=findFlights&searchByCabin=true&deltaOnlySearch=false&deltaOnly=off&go=Find%20Flights&tripType=ONE_WAY&passengerInfo=ADT:1&priceSchedule=price&awardTravel=true&originCity=${from}&destinationCity=${to}&departureDate=${date}&returnDate=&forceMiles=true&utm_source=seatsaero`
  },
  etihad: {
    name: 'Etihad Guest',
    logoURL: 'https://driptours.com/wp-content/uploads/2024/11/etihad.png',
    composeURL: ({ from, to, date }) =>
      `https://digital.etihad.com/book/search?LANGUAGE=EN&CHANNEL=DESKTOP&B_LOCATION=${from}&E_LOCATION=${to}&TRIP_TYPE=O&CABIN=E&TRAVELERS=ADT&TRIP_FLOW_TYPE=AVAILABILITY&DATE_1=${date
        .split('-')
        .join('')}0000&WDS_ENABLE_MILES_TOGGLE=TRUE&FLOW=AWARD`
  },
  united: {
    name: 'United MileagePlus',
    logoURL: 'https://driptours.com/wp-content/uploads/2024/11/united.png',
    composeURL: ({ from, to, date }) =>
      `https://www.united.com/en/us/fsr/choose-flights?f=${from}&t=${to}&d=${date}&sc=7&st=bestmatches&cbm=-1&cbm2=-1&ft=0&cp=0&tt=1&at=1&rm=1&act=0&px=1&taxng=1&clm=7&tqp=A`
  },
  emirates: {
    name: 'Emirates Skywards',
    logoURL: 'https://driptours.com/wp-content/uploads/2024/11/emirates.png',
    composeURL: ({ from, to }) =>
      `https://www.aa.com/booking/search?type=OneWay&searchType=Award&from=${from}&to=${to}&pax=1&cabin=&locale=en_US&nearbyAirports=true&depart=2024-11-11&carriers=ALL&pos=US&adult=1&utm_source=seatsaero`
  },
  aeroplan: {
    name: 'Air Canada Aeroplan',
    logoURL: 'https://driptours.com/wp-content/uploads/2024/11/aeroplan.png',
    composeURL: ({ from, to, date }) =>
      `https://www.aircanada.com/aeroplan/redeem/availability/outbound?org0=${from}&dest0=${to}&departureDate0=${date}&lang=en-CA&tripType=O&ADT=1&YTH=0&CHD=0&INF=0&INS=0&marketCode=INT`
  },
  alaska: {
    name: 'Alaska Mileage Plan',
    logoURL: 'https://driptours.com/wp-content/uploads/2024/11/alaska.png',
    composeURL: ({ from, to, date }) =>
      // 2024-11-07
      `https://www.alaskaair.com/search/results?O=${from}&D=${to}&OD=${date}&A=1&C=0&L=0&RT=false&ShoppingMethod=onlineaward`
  },
  velocity: {
    name: 'Virgin Australia Velocity',
    logoURL: 'https://driptours.com/wp-content/uploads/2024/11/velocity.png',
    composeURL: ({ from, to, date }) =>
      `https://book.virginaustralia.com/dx/VADX/#/flight-selection?journeyType=one-way&activeMonth=${dayjs(date).format(
        'MM-DD-YYYY'
      )}&awardBooking=true&class=First&ADT=1&CHD=0&INF=0&origin=${from}&destination=${to}&date=${dayjs(date).format('MM-DD-YYYY')}`
  },
  qantas: {
    name: 'Qantas Frequent Flyer',
    logoURL: 'https://driptours.com/wp-content/uploads/2024/11/qantas.png',
    composeURL: () => `https://www.qantas.com/us/en/book-a-trip/flights.html?showMod=0&utm_source=seatsaero`
  },
  copa: {
    name: 'Copa ConnectMiles',
    logoURL: 'https://driptours.com/wp-content/uploads/2024/11/connectmiles.png',
    composeURL: ({ from, to, date }) =>
      `https://www.aa.com/booking/search?type=OneWay&searchType=Award&from=${from}&to=${to}&pax=1&cabin=&locale=en_US&nearbyAirports=true&depart=${date}&carriers=ALL&pos=US&adult=1&utm_source=seatsaero`
  },
  azul: {
    name: 'Azul TudoAzul',
    logoURL: 'https://driptours.com/wp-content/uploads/2024/11/azul.png',
    composeURL: ({ from, to, date }) =>
      `https://www.aa.com/booking/search?type=OneWay&searchType=Award&from=${from}&to=${to}&pax=1&cabin=&locale=en_US&nearbyAirports=true&depart=${date}&carriers=ALL&pos=US&adult=1&utm_source=seatsaero`
  },
  smiles: {
    name: 'GOL Smiles',
    logoURL: 'https://driptours.com/wp-content/uploads/2024/11/smiles.png',
    composeURL: ({ from, to, date }) =>
      `https://www.smiles.com.br/mfe/emissao-passagem/?adults=1&cabin=ALL&children=0&departureDate=${dayjs(
        date
      ).valueOf()}&infants=0&isElegible=false&isFlexibleDateChecked=false&returnDate=&searchType=congenere&segments=1&tripType=2&originAirport=${from}&originCity=&originCountry=&originAirportIsAny=false&destinationAirport=${to}&destinCity=&destinCountry=&destinAirportIsAny=false&novo-resultado-voos=true`
  },
  flyingblue: {
    name: 'Air France/KLM Flying Blue',
    logoURL: 'https://driptours.com/wp-content/uploads/2024/11/flyingblue.png',
    composeURL: ({ from }) =>
      `https://wwws.airfrance.us/search/offers?activeConnection=0&bookingFlow=REWARD&cabinClass=ECONOMY&pax=1:0:0:0:0:0:0:0&connections=${from}%3AC%3A20241113%3EFCO%3AA&utm_source=seatsaero`
  },
  jetblue: {
    name: 'JetBlue TrueBlue',
    logoURL: 'https://driptours.com/wp-content/uploads/2024/11/jetblue.png',
    composeURL: ({ from, to, date }) =>
      `https://www.jetblue.com/booking/flights?from=${from}&to=${to}&depart=${date}&isMultiCity=false&noOfRoute=1&lang=en&adults=1&children=0&infants=0&sharedMarket=false&roundTripFaresFlag=true&usePoints=true`
  }
};

const unavailableMileagePrograms = ['flyingblue', 'qantas', 'eurobonus', 'azul', 'aeromexico'];

const airlines = {
  aa: 'American Airlines',
  as: 'Alaska Airlines',
  co: 'Continental Airlines',
  dl: 'Delta Air Lines',
  hp: 'America West Airlines',
  ua: 'United Airlines'
};

const tabOptions = ['Free', 'Basic', 'Premium'];

const horizonToScan = {
  next_week: 'Flights within next week',
  next_month: 'Flights within next month',
  next_2_months: 'Flights within next 2 months'
};

export {
  flightTypes,
  sampleOutput,
  budgetOptions,
  companionOptions,
  activityOptions,
  mileagePrograms,
  airlines,
  unavailableMileagePrograms,
  tabOptions,
  horizonToScan
};
