import { useState, useCallback, useMemo, useEffect } from 'react';
import PropTypes from 'prop-types';

import callAzureFunction from 'utils/callAzureFunctions';
import { Grid, Box, Typography, Switch, FormControlLabel, Tabs, Tab } from '@mui/material';
import { DataGrid, GRID_CHECKBOX_SELECTION_COL_DEF } from '@mui/x-data-grid';
import { formatStripeAmount } from 'utils/transaction-helpers';
import dayjs from 'dayjs';
import { enqueueSnackbar } from 'notistack';

const NoInvoicesFound = () => (
  <Box display="flex" justifyContent="center" alignItems="center" height="100%">
    <Typography variant="h5" color="primary">
      No invoices found
    </Typography>
  </Box>
);

const ManualPayment = ({ influencer, setSelectedInvoices, setSelectedFirstPaymentInvoices }) => {
  const [isLoading, setIsLoading] = useState(false);

  //   const [referredUsersInvoicesFP, setReferredUsersInvoicesFP] = useState([]);
  const [referredUsersInvoices, setReferredUsersInvoices] = useState([]);
  const [filter30daysOldInvoices, setFilter30daysOldInvoices] = useState(true);
  const tabOptions = [
    { value: 'first_payments', label: 'First Payments' },
    { value: 'succeeding_payments', label: 'Succeeding Payments' }
  ];

  const [tabValue, setTabValue] = useState(tabOptions[0].value);

  const invoiceSchedule = useMemo(
    () => [
      {
        ...GRID_CHECKBOX_SELECTION_COL_DEF,
        headerName: 'Mark As',
        width: 100,
        align: 'center',
        headerAlign: 'center'
      },
      {
        field: 'id',
        headerName: 'ID',
        width: 100,
        align: 'center',
        headerAlign: 'center',
        renderCell: (params) => params.value
      },
      {
        field: 'customer_email',
        headerName: 'User',
        width: 120,
        flex: 1
      },
      {
        field: 'created_at',
        headerName: 'Date Paid',
        width: 150,
        flex: 0.5,
        renderCell: (params) => dayjs.unix(params.row.created).format('MMM DD, YYYY')
      },
      {
        field: 'aging',
        headerName: 'No. of Days Old',
        width: 150,
        flex: 0.5,
        renderCell: (params) => {
          const { created } = params.row;

          const age = dayjs().diff(dayjs.unix(created), 'days');

          return age;
        }
      },
      {
        field: 'amount_paid',
        headerName: 'Amount Paid By User',
        width: 150,
        flex: 0.5,
        renderCell: (params) => formatStripeAmount(params.row.total)
      },
      {
        field: 'referral_fee',
        headerName: 'Referral Fee',
        width: 150,
        flex: 0.5,
        renderCell: (params) => {
          const { metadata, total } = params.row;

          const referralFee = metadata.is_first_subscription_payment ? total : total * 0.2;

          return formatStripeAmount(referralFee);
        }
      }
    ],
    []
  );

  const handleSelectionChange = (newSelection) => {
    const newSelectionWithProps = newSelection.map((id) => ({ ...referredUsersInvoices?.find((row) => row.id === id) }));
    if (tabValue === 'first_payments') {
      setSelectedFirstPaymentInvoices(newSelectionWithProps);
    } else if (tabValue === 'succeeding_payments') {
      setSelectedInvoices(newSelectionWithProps);
    }
  };

  const getReferredUsersInvoicesByInfluencer = useCallback(async () => {
    try {
      setIsLoading(true);
      const response = await callAzureFunction({
        url: 'influencer/invoices-by-referred-users',
        method: 'post',
        data: { user_email: influencer?.email, filter_30_days_old_invoices: filter30daysOldInvoices, target: tabValue }
      });

      setReferredUsersInvoices(response.data);
    } catch (error) {
      enqueueSnackbar({
        variant: 'error',
        message: error.response?.data,
        anchorOrigin: { vertical: 'top', horizontal: 'right' }
      });
    } finally {
      setIsLoading(false);
    }
  }, [influencer, filter30daysOldInvoices, tabValue]);

  useEffect(() => {
    getReferredUsersInvoicesByInfluencer();
  }, [getReferredUsersInvoicesByInfluencer]);

  return (
    <>
      <Grid container spacing={2}>
        <Grid item xs={12} container>
          <Grid item xs={6}>
            <Tabs
              value={tabValue}
              onChange={(event, newValue) => {
                setTabValue(newValue);
              }}
              // textColor="secondary"
              TabIndicatorProps={{
                style: { backgroundColor: 'primary.main' }
              }}
              indicatorColor="primary"
              sx={{ mb: 1 }}
            >
              {tabOptions.map((tab, index) => (
                <Tab key={index} label={tab.label} value={tab.value} disabled={isLoading} />
              ))}
            </Tabs>
          </Grid>
          <Grid item xs={6} display="flex" justifyContent="center">
            <FormControlLabel
              control={
                <Switch
                  checked={filter30daysOldInvoices}
                  onChange={(e) => setFilter30daysOldInvoices(e.target.checked)}
                  disabled={isLoading}
                />
              }
              label="Only show invoices that are 30 days old"
            />
          </Grid>
          <DataGrid
            rows={referredUsersInvoices}
            columns={invoiceSchedule}
            loading={isLoading}
            disableRowSelectionOnClick
            disableColumnFilter
            disableColumnMenu
            getRowId={(row) => row.id}
            localeText={{ noRowsLabel: 'No invoices found', noResultsOverlayLabel: 'No result found !!!' }}
            // hideFooterPagination
            hideFooterSelectedRowCount
            checkboxSelection
            autoHeight
            onRowSelectionModelChange={(newSelectionModel) => handleSelectionChange(newSelectionModel)}
            slotProps={{
              loadingOverlay: {
                variant: 'linear-progress',
                noRowsVariant: 'skeleton'
              }
            }}
            slots={{
              noRowsOverlay: NoInvoicesFound
            }}
          />
        </Grid>
        <Grid item xs={6} />
      </Grid>
    </>
  );
};

ManualPayment.propTypes = {
  influencer: PropTypes.object,
  setSelectedInvoices: PropTypes.any,
  setSelectedFirstPaymentInvoices: PropTypes.any
};

export default ManualPayment;
