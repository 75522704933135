import { useState, useMemo, useCallback, useEffect } from 'react';
import PropTypes from 'prop-types';

import dayjs from 'dayjs';
import { Dialog, DialogContent, Button, Typography, DialogActions, Tabs, Tab, Box, Stack, Grid } from '@mui/material';
import { DataGrid } from '@mui/x-data-grid';
import callAzureFunction from 'utils/callAzureFunctions';
import { formatStripeAmount } from 'utils/transaction-helpers';
import { enqueueSnackbar } from 'notistack';
import PaymentBreakdown from './accrual-table-content/PaymentBreakdown';
import PaymentConfirmationDialog from './PaymentConfirmationDialog';

const CustomFooter = ({ totalReferralDue }) => (
  <Box
    display="flex"
    justifyContent="space-between"
    alignItems="center"
    padding={1}
    sx={{ backgroundColor: '#f0f0f0', fontWeight: 'bold' }}
  >
    <Typography variant="h5">Total Referral Fee Due</Typography>
    <Typography>{formatStripeAmount(totalReferralDue)}</Typography>
  </Box>
);

CustomFooter.propTypes = {
  totalReferralDue: PropTypes.number
};

const StripeTransferDialog = ({ open, setOpen, influencer }) => {
  const [isLoading, setIsLoading] = useState(false);
  const [balance, setBalance] = useState({});
  const [firstPayments, setFirstPayments] = useState([]);
  const [succeedingPayments, setSucceedingPayments] = useState([]);

  const [openPaymentConfirmationDialog, setOpenPaymentConfirmationDialog] = useState(false);
  const selectedInvoices = [];

  const tabOptions = ['Referral Fee Breakdown', 'Payment Summary'];
  const [tabValue, setTabValue] = useState(tabOptions[0]);
  // const totalPayments = '0.00';

  const referralFeeFirstPayment = firstPayments.reduce((acc, curr) => curr.total + acc, 0) || 0;
  const referralFeeSucceedingBase = succeedingPayments.reduce((acc, curr) => curr?.total || 0 + acc, 0) || 0;

  const referralFeeSucceeding = referralFeeSucceedingBase * 0.2;
  const transactionFees = (referralFeeSucceeding + referralFeeFirstPayment) * 0.05;
  const netPayment = referralFeeSucceeding + referralFeeFirstPayment - transactionFees;

  const getAvailableBalance = useCallback(async () => {
    try {
      const response = await callAzureFunction({ url: 'stripe/balance/admin', method: 'get' });

      const { available, pending } = response?.data || {};
      setBalance({ available: available[0]?.amount, pending: pending[0]?.amount });
    } catch (error) {
      console.error(error);
    }
  }, []);

  const getDueReferralFee = useCallback(async () => {
    try {
      setIsLoading(true);
      const response = await callAzureFunction({
        url: 'influencer/due-referral-fees',
        method: 'post',
        data: { user_email: influencer?.email }
      });

      // eslint-disable-next-line camelcase
      const { first_payments, succeeding_payments } = response?.data || {};

      setFirstPayments(first_payments);
      setSucceedingPayments(succeeding_payments);
    } catch (error) {
      enqueueSnackbar({ variant: 'error', message: error.response?.data, anchorOrigin: { vertical: 'top', horizontal: 'right' } });
    } finally {
      setIsLoading(false);
    }
  }, [influencer]);

  const columns = useMemo(
    () => [
      {
        field: 'id',
        headerName: 'ID',
        width: 100,
        align: 'center',
        headerAlign: 'center',
        renderCell: (params) => params.value
      },
      {
        field: 'customer_email',
        headerName: 'User',
        width: 150,
        flex: 1
      },
      {
        field: 'created_at',
        headerName: 'Date Paid',
        width: 150,
        flex: 0.5,
        renderCell: (params) => dayjs.unix(params.row.created).format('MMM DD, YYYY')
      },
      {
        field: 'aging',
        headerName: 'No. of Days Old',
        width: 150,
        flex: 0.5,
        renderCell: (params) => {
          const { created } = params.row;

          const age = dayjs().diff(dayjs.unix(created), 'days');

          return age;
        }
      },
      {
        field: 'amount_paid',
        headerName: 'Amount Paid',
        width: 150,
        flex: 0.5,
        align: 'right',
        renderCell: (params) => formatStripeAmount(params.row.total)
      }
    ],
    []
  );

  useEffect(() => {
    getAvailableBalance();
  }, [getAvailableBalance]);

  useEffect(() => {
    getDueReferralFee();
  }, [getDueReferralFee]);

  return (
    <Dialog open={open} onClose={() => setOpen(false)} fullWidth maxWidth="md">
      <Tabs
        value={tabValue}
        textColor="black"
        indicatorColor="primary"
        onChange={(event, newValue) => {
          setTabValue(newValue);
        }}
      >
        {tabOptions.map((tab) => (
          <Tab key={tab} label={tab} value={tab} />
        ))}
      </Tabs>
      <DialogContent>
        <Grid container>
          <Grid item xs={6}>
            <Stack my={1}>
              <Typography variant="h4">Payment for January 2025</Typography>
              <Typography variant="body1">Due on 01/31/2025</Typography>
            </Stack>
          </Grid>
          <Grid item xs={6} display="flex" justifyContent="center">
            <Stack my={1}>
              <Typography variant="h4" color="primary">
                Available Balance
              </Typography>
              <Typography variant="h3" textAlign="right">
                {formatStripeAmount(balance?.available)}
              </Typography>
            </Stack>
          </Grid>
        </Grid>

        {tabValue === tabOptions[0] && (
          <>
            <Typography variant="h4" my={2}>
              First Subscription Payments
            </Typography>
            <DataGrid
              rows={firstPayments}
              columns={columns}
              loading={isLoading}
              disableRowSelectionOnClick
              disableColumnFilter
              disableColumnMenu
              autoHeight
              getRowHeight={() => 'auto'}
              getRowId={(row) => row.id}
              localeText={{ noRowsLabel: 'No payments found' }}
              slots={{
                footer: CustomFooter // Replace footer with custom component
              }}
              slotProps={{
                footer: { totalReferralDue: referralFeeFirstPayment }
              }}
            />
            <Typography variant="h4" my={2}>
              Succeeding Payments
            </Typography>
            <DataGrid
              rows={succeedingPayments}
              columns={columns}
              loading={isLoading}
              disableRowSelectionOnClick
              disableColumnFilter
              disableColumnMenu
              autoHeight
              getRowHeight={() => 'auto'}
              getRowId={(row) => row.id}
              localeText={{ noRowsLabel: 'No payments found' }}
              slots={{
                footer: CustomFooter // Replace footer with custom component
              }}
              slotProps={{
                footer: { totalReferralDue: referralFeeSucceeding }
              }}
            />
          </>
        )}

        {tabValue === tabOptions[1] && (
          <>
            <PaymentBreakdown
              referralFeeFirstPayment={referralFeeFirstPayment}
              referralFeeSucceedingBase={referralFeeSucceedingBase}
              referralFeeSucceeding={referralFeeSucceeding}
              transactionFees={transactionFees}
              netPayment={netPayment}
            />
          </>
        )}
      </DialogContent>
      <DialogActions>
        {/* {tabValue === tabOptions[1] && (
          <Button variant="contained">
            Pay
          </Button>
        )} */}
        <Button onClick={() => setOpen(false)}>Close</Button>
      </DialogActions>
      <PaymentConfirmationDialog
        open={openPaymentConfirmationDialog}
        setOpen={setOpenPaymentConfirmationDialog}
        influencer={influencer}
        invoices={selectedInvoices}
        referralFeeFirstPayment={referralFeeFirstPayment}
        referralFeeSucceedingBase={referralFeeSucceedingBase}
        referralFeeSucceeding={referralFeeSucceeding}
        transactionFees={transactionFees}
        netPayment={netPayment}
      />
    </Dialog>
  );
};

StripeTransferDialog.propTypes = {
  open: PropTypes.bool,
  setOpen: PropTypes.func,
  influencer: PropTypes.object
};

export default StripeTransferDialog;
