import PropTypes from 'prop-types';
import { Typography, TableContainer, Table, TableRow, TableCell, TableBody, Paper } from '@mui/material';
import { formatStripeAmount } from 'utils/transaction-helpers';

// eslint-disable-next-line arrow-body-style
const PaymentBreakdown = ({ referralFeeFirstPayment, referralFeeSucceedingBase, referralFeeSucceeding, transactionFees, netPayment }) => {
  return (
    <TableContainer component={Paper} sx={{ mt: 2 }}>
      <Table dense size="small">
        <TableBody>
          <TableRow>
            <TableCell component="th" scope="row" sx={{ borderBottom: 'none' }}>
              Referral Fee for First Month Subscription Payments
            </TableCell>
            <TableCell align="right" sx={{ borderBottom: 'none' }}>
              {formatStripeAmount(referralFeeFirstPayment)}
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell component="th" scope="row">
              Referral Fee for Succeeding Subscription Payments
              <Typography display="inline" variant="caption" mx={0.5}>
                (set at
              </Typography>
              <Typography display="inline" variant="h5">
                20%
              </Typography>
              <Typography display="inline" variant="caption" ml={0.3}>
                ) of {formatStripeAmount(referralFeeSucceedingBase)}
              </Typography>
            </TableCell>
            <TableCell align="right">{formatStripeAmount(referralFeeSucceeding)}</TableCell>
          </TableRow>
          <TableRow>
            <TableCell component="th" scope="row">
              Less: Transaction Fees
              <Typography display="inline" variant="caption" mx={0.5}>
                (set at
              </Typography>
              <Typography display="inline" variant="h5">
                0.5%
              </Typography>
              <Typography display="inline" variant="caption" ml={0.3}>
                )
              </Typography>
            </TableCell>
            <TableCell align="right">
              <Typography color="error">{formatStripeAmount(transactionFees)}</Typography>
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell component="th" scope="row">
              Total Payment
            </TableCell>
            <TableCell align="right">
              <Typography variant="subtitle1">{formatStripeAmount(netPayment)}</Typography>
            </TableCell>
          </TableRow>
        </TableBody>
      </Table>
    </TableContainer>
  );
};

PaymentBreakdown.propTypes = {
  referralFeeFirstPayment: PropTypes.number,
  referralFeeSucceedingBase: PropTypes.number,
  referralFeeSucceeding: PropTypes.number,
  transactionFees: PropTypes.number,
  netPayment: PropTypes.number
};

export default PaymentBreakdown;
