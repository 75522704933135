// utils
import handleError from 'utils/handle-error';
import clearReduxStore from 'utils/clear-redux-store';
import { callAzureFuncPublic } from './call-azure-function-auth';

const handleLogout = async () => {
  try {
    // invalidate cookies
    await callAzureFuncPublic({
      url: `/logout`,
      method: 'post'
    });
  } catch (error) {
    handleError(error);
  } finally {
    // clear redux store values
    clearReduxStore();

    // initiate logout action to all other tabs
    localStorage.setItem('isLoggedIn', false);
  }
};

// eslint-disable-next-line import/prefer-default-export
export { handleLogout };
