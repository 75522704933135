import axios from 'axios';

const functionConfig = {
  baseURL: import.meta.env.VITE_APP_AZ_FUNCTIONS_BASE_URI,
  withCredentials: true
};

const callAzureFuncPublic = async (requestConfig) => {
  const axiosResponse = await axios({
    ...requestConfig,
    ...functionConfig
  });

  return axiosResponse;
};

// eslint-disable-next-line import/prefer-default-export
export { callAzureFuncPublic };
