import { useState, useMemo, useCallback, useEffect } from 'react';
import PropTypes from 'prop-types';

import dayjs from 'dayjs';
import { Dialog, DialogContent, Button, Typography, DialogActions, Tabs, Tab, Box } from '@mui/material';
import { DataGrid } from '@mui/x-data-grid';
import callAzureFunction from 'utils/callAzureFunctions';
import { formatStripeAmount } from 'utils/transaction-helpers';
import { enqueueSnackbar } from 'notistack';
import PaymentBreakdown from '../accrual-table-content/PaymentBreakdown';

const CustomFooter = ({ totalReferralDue }) => (
  <Box
    display="flex"
    justifyContent="space-between"
    alignItems="center"
    padding={1}
    sx={{ backgroundColor: '#f0f0f0', fontWeight: 'bold' }}
  >
    <Typography variant="h5">Gross Referral Fee Due</Typography>
    <Typography>{formatStripeAmount(totalReferralDue)}</Typography>
  </Box>
);

CustomFooter.propTypes = {
  totalReferralDue: PropTypes.number
};

const TransferBreakdownDialog = ({ open, setOpen, transferId }) => {
  const [isLoading, setIsLoading] = useState(false);

  const [referralFeeFP, setReferralFeeFP] = useState(0);
  const [referralFeeSP, setReferralFeeSP] = useState(0);

  const [firstPayments, setFirstPayments] = useState([]);
  const [succeedingPayments, setSucceedingPayments] = useState([]);

  const tabOptions = ['Referral Fee Breakdown', 'Payment Breakdown'];
  const [tabValue, setTabValue] = useState(tabOptions[0]);

  const referralFeeSucceedingBase = succeedingPayments.reduce((acc, curr) => curr?.total || 0 + acc, 0) || 0;

  const transactionFees = (referralFeeSP + referralFeeFP) * 0.05;
  const netPayment = referralFeeSP + referralFeeFP - transactionFees;

  const getTransferBreakdown = useCallback(async () => {
    try {
      setIsLoading(true);
      const response = await callAzureFunction({
        url: 'stripe/get-invoices-by-transfer-id',
        method: 'post',
        data: { transfer_id: transferId }
      });

      const result = response.data;

      const firstPayments = result?.invoices_to_pay.filter((inv) => inv.metadata?.is_first_subscription_payment) || [];

      setFirstPayments(firstPayments);

      const succeedingPayments = result?.invoices_to_pay.filter((inv) => !inv.metadata?.is_first_subscription_payment) || [];

      setSucceedingPayments(succeedingPayments);

      setReferralFeeFP(result.referral_fee_from_fp);
      setReferralFeeSP(result.referral_fee_from_sp);
    } catch (error) {
      enqueueSnackbar({
        variant: 'error',
        message: 'No invoices available',
        anchorOrigin: { vertical: 'top', horizontal: 'right' }
      });
    } finally {
      setIsLoading(false);
    }
  }, [transferId]);

  const columns = useMemo(
    () => [
      {
        field: 'id',
        headerName: 'Transaction ID',
        width: 200,
        align: 'center',
        headerAlign: 'center',
        renderCell: (params) => params.value
      },
      {
        field: 'customer_email',
        headerName: 'User',
        width: 150,
        flex: 1
      },
      {
        field: 'created_at',
        headerName: 'Date Paid',
        width: 150,
        flex: 0.5,
        renderCell: (params) => dayjs.unix(params.row.created).format('MMM DD, YYYY')
      },
      {
        field: 'aging',
        headerName: 'No. of Days Old',
        width: 150,
        flex: 0.5,
        renderCell: (params) => {
          const { created } = params.row;

          const age = dayjs().diff(dayjs.unix(created), 'days');

          return age;
        }
      },
      {
        field: 'amount_paid',
        headerName: 'Amount Paid By User',
        width: 150,
        flex: 0.5,
        align: 'right',
        renderCell: (params) => formatStripeAmount(params.row.total)
      },
      {
        field: 'ref_fee',
        headerName: 'Referral Fee',
        width: 150,
        flex: 0.5,
        align: 'right',
        renderCell: (params) => {
          const { metadata, total } = params.row;

          const referralFee = metadata.is_first_subscription_payment ? total : total * 0.2;

          return formatStripeAmount(referralFee);
        }
      }
    ],
    []
  );

  const handleClose = () => {
    // reset states
    setReferralFeeFP(0);
    setReferralFeeSP(0);
    setFirstPayments([]);
    setSucceedingPayments([]);

    setOpen(false);
  };

  // useEffect(() => {
  //   getAvailableBalance();
  // }, [getAvailableBalance]);

  useEffect(() => {
    if (open) getTransferBreakdown();
  }, [getTransferBreakdown, open]);

  return (
    <Dialog open={open} onClose={handleClose} fullWidth maxWidth="lg">
      <Tabs
        value={tabValue}
        textColor="black"
        indicatorColor="primary"
        onChange={(event, newValue) => {
          setTabValue(newValue);
        }}
      >
        {tabOptions.map((tab) => (
          <Tab key={tab} label={tab} value={tab} disabled={isLoading} />
        ))}
      </Tabs>
      <DialogContent>
        {/* <Grid container>
          <Grid item xs={6}>
            <Stack my={1}>
              <Typography variant="h4">Payment for January 2025</Typography>
              <Typography variant="body1">Due on 01/31/2025</Typography>
            </Stack>
          </Grid>
          <Grid item xs={6} display="flex" justifyContent="center">
            <Stack my={1}>
              <Typography variant="h4" color="primary">
                Available Balance
              </Typography>
              <Typography variant="h3" textAlign="right">
                {formatStripeAmount(balance?.available)}
              </Typography>
            </Stack>
          </Grid>
        </Grid> */}

        {tabValue === tabOptions[0] && (
          <>
            <Typography variant="h4" my={2}>
              First Subscription Payments
            </Typography>
            <DataGrid
              rows={firstPayments}
              columns={columns}
              loading={isLoading}
              disableRowSelectionOnClick
              disableColumnFilter
              disableColumnMenu
              autoHeight
              getRowHeight={() => 'auto'}
              getRowId={(row) => row.id}
              localeText={{ noRowsLabel: 'No payments found' }}
              slots={{
                footer: CustomFooter // Replace footer with custom component
              }}
              slotProps={{
                footer: { totalReferralDue: referralFeeFP }
              }}
            />
            <Typography variant="h4" my={2}>
              Succeeding Payments
            </Typography>
            <DataGrid
              rows={succeedingPayments}
              columns={columns}
              loading={isLoading}
              disableRowSelectionOnClick
              disableColumnFilter
              disableColumnMenu
              autoHeight
              getRowHeight={() => 'auto'}
              getRowId={(row) => row.id}
              localeText={{ noRowsLabel: 'No payments found' }}
              slots={{
                footer: CustomFooter // Replace footer with custom component
              }}
              slotProps={{
                footer: { totalReferralDue: referralFeeSP }
              }}
            />
          </>
        )}

        {tabValue === tabOptions[1] && (
          <>
            <PaymentBreakdown
              referralFeeFirstPayment={referralFeeFP}
              referralFeeSucceedingBase={referralFeeSucceedingBase}
              referralFeeSucceeding={referralFeeSP}
              transactionFees={transactionFees}
              netPayment={netPayment}
            />
          </>
        )}
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose}>Close</Button>
      </DialogActions>
    </Dialog>
  );
};

TransferBreakdownDialog.propTypes = {
  open: PropTypes.bool,
  setOpen: PropTypes.func,
  transferId: PropTypes.string
};

export default TransferBreakdownDialog;
