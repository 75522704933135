import { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate, useLocation } from 'react-router-dom';

const PortalProtector = ({ children }) => {
  const navigate = useNavigate();
  const location = useLocation();

  const { isLoggedIn, data: userData } = useSelector((state) => state.user) || {};

  useEffect(() => {
    const isInfluencerLoc = location.pathname?.includes('influencer');
    const isAdminLoc = location.pathname?.includes('admin');
    if (!isLoggedIn) {
      navigate(isInfluencerLoc ? '/influencer/login' : '/login');
    } else if ((isInfluencerLoc && userData.userType !== 'influencer') || (isAdminLoc && userData.userType !== 'admin')) {
      navigate('/my-apps');
    }
  }, [isLoggedIn, navigate, location, userData]);

  return children;
};

export default PortalProtector;
