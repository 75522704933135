import PropTypes from 'prop-types';
import { useState } from 'react';
import { useSelector } from 'react-redux';

import { Dialog, DialogTitle, DialogContent, DialogActions, Button, LinearProgress, Typography } from '@mui/material';
import { enqueueSnackbar } from 'notistack';

import callAzureFunction from 'utils/callAzureFunctions';
import PaymentBreakdown from './accrual-table-content/PaymentBreakdown';

const PaymentConfirmationDialog = ({
  open,
  setOpen,
  influencer,
  invoices,
  referralFeeFirstPayment,
  referralFeeSucceedingBase,
  referralFeeSucceeding,
  transactionFees,
  netPayment
}) => {
  const userData = useSelector((state) => state.user?.data);
  const [isLoading, setIsLoading] = useState(false);
  const payInfluencer = async () => {
    try {
      setIsLoading(true);
      await callAzureFunction({
        url: 'stripe/connect/transfer',
        method: 'post',
        data: {
          influencer_email: influencer?.email,
          amount: netPayment,
          transacted_by: userData?.userEmail,
          invoice_ids: invoices.map((invoice) => invoice.id)
        }
      });
      enqueueSnackbar({ variant: 'success', message: 'Transfer success', anchorOrigin: { vertical: 'top', horizontal: 'right' } });
      setOpen(false);
    } catch (error) {
      console.log(error);
      enqueueSnackbar({
        variant: 'error',
        message: error.response?.data,
        anchorOrigin: { vertical: 'top', horizontal: 'right' }
      });
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <Dialog open={open} onClose={() => setOpen(false)} fullWidth maxWidth="sm">
      {isLoading && <LinearProgress />}
      <DialogTitle variant="h3">Payment Confirmation</DialogTitle>
      <DialogContent>
        <Typography>Please confirm the payment</Typography>
        <PaymentBreakdown
          referralFeeFirstPayment={referralFeeFirstPayment}
          referralFeeSucceedingBase={referralFeeSucceedingBase}
          referralFeeSucceeding={referralFeeSucceeding}
          transactionFees={transactionFees}
          netPayment={netPayment}
        />
      </DialogContent>
      <DialogActions>
        <Button onClick={payInfluencer} disabled={isLoading} variant="contained">
          Proceed
        </Button>
        <Button onClick={() => setOpen(false)} disabled={isLoading}>
          Cancel
        </Button>
      </DialogActions>
    </Dialog>
  );
};

PaymentConfirmationDialog.propTypes = {
  open: PropTypes.bool,
  setOpen: PropTypes.func,
  influencer: PropTypes.object,
  netPayment: PropTypes.number,
  invoices: PropTypes.array,
  referralFeeFirstPayment: PropTypes.number,
  referralFeeSucceedingBase: PropTypes.number,
  referralFeeSucceeding: PropTypes.number,
  transactionFees: PropTypes.number
};

export default PaymentConfirmationDialog;
