import { useState } from 'react';
import PropTypes from 'prop-types';

// MUI
import { IconButton, Menu, MenuItem, Divider } from '@mui/material';
import MoreVertIcon from '@mui/icons-material/MoreVert';

const MenuActionButton = ({ menuItems, buttonComponent: ButtonComponent = IconButton, buttonComponentProps = {} }) => {
  /**
   * Creates a button that triggers a menu when clicked.
   *
   * @param {Object[]} menuItems - An array of menu items. Each object in the array represents a menu item.
   * @param {boolean} [menuItems[].divider=false]- If set, the menu item is rendered as a divider and other properties are ignored.
   * @param {React.Element} [menuItems[].content]- The content of the menu item. This could be text or a React Element.
   * @param {boolean} [menuItems[].visible=true]- Whether the menu item should be visible.
   * @param {boolean} [menuItems[].disabled=false]- Whether the menu item should be disabled. If true, the item is displayed but greyed out and not interactive.
   * @param {function} [menuItems[].onClick]- Callback that will be invoked when the menu item is clicked.
   * @param {Object[]} [menuItems[].subMenu]- An array that follows the same structure as menuItems[]. When clicked, a submenu is displayed. Note: nested subMenus are not supported.
   * @param {React.ElementType} buttonComponent - The component to render as the button that triggers the menu.
   * @param {Object} buttonComponentProps - Additional props to pass to the button component.
   */

  menuItems = menuItems.filter((item) => item.visible !== false);
  const [anchorEl, setAnchorEl] = useState(null);
  const [subMenuAnchorEl, setSubMenuAnchorEl] = useState(null);
  const [subMenuItems, setSubMenuItems] = useState(null);

  const handleMenuClick = (event) => {
    event.stopPropagation();
    setAnchorEl(event.currentTarget);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  const handleSubMenuClose = () => {
    setSubMenuAnchorEl(null);
    setSubMenuItems(null);
  };

  const handleSubMenuClick = (event, subMenuItems) => {
    if (!subMenuAnchorEl) {
      setSubMenuAnchorEl(event.currentTarget);
      setSubMenuItems(subMenuItems);
    } else {
      handleSubMenuClose();
    }
  };

  return (
    <div>
      <ButtonComponent onClick={handleMenuClick} {...buttonComponentProps}>
        {buttonComponentProps.children || <MoreVertIcon />}
      </ButtonComponent>
      {/* zIndex is set to this value to properly display the Menu whenever the custom snackbar would show up. Menu's zIndex should have lesser value than the custom snackbar. */}
      <Menu anchorEl={anchorEl} open={Boolean(anchorEl)} onClose={handleMenuClose} sx={{ zIndex: 120 }}>
        {menuItems.map((item, index) => {
          if (item.divider) {
            return <Divider key={index} />;
          }
          const isSubMenuOpen = subMenuItems === item.subMenu;
          return (
            <MenuItem
              key={index}
              visible={item.visible}
              disabled={item.disabled}
              onClick={(e) => {
                if (item.subMenu) {
                  handleSubMenuClick(e, item.subMenu);
                } else {
                  handleMenuClose();
                  if (item.onClick) {
                    item.onClick();
                  }
                }
              }}
            >
              {item.content}
              {isSubMenuOpen && (
                <Menu
                  anchorEl={subMenuAnchorEl}
                  open={Boolean(subMenuAnchorEl)}
                  onClose={handleSubMenuClose}
                  anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
                  transformOrigin={{ vertical: 'top', horizontal: 'left' }}
                  getContentAnchorEl={null}
                >
                  {subMenuItems.map((subItem, subIndex) => (
                    <MenuItem
                      key={subIndex}
                      visible={subItem.visible}
                      disabled={subItem.disabled}
                      onClick={() => {
                        handleSubMenuClose();
                        if (subItem.onClick) {
                          subItem.onClick();
                        }
                      }}
                    >
                      {subItem.content}
                    </MenuItem>
                  ))}
                </Menu>
              )}
            </MenuItem>
          );
        })}
      </Menu>
    </div>
  );
};

MenuActionButton.propTypes = {
  menuItems: PropTypes.arrayOf(
    PropTypes.shape({
      content: PropTypes.any,
      onClick: PropTypes.func,
      visible: PropTypes.bool,
      subMenu: PropTypes.arrayOf(
        PropTypes.shape({
          content: PropTypes.any,
          onClick: PropTypes.func
        })
      )
    })
  ).isRequired,
  buttonComponent: PropTypes.elementType,
  buttonComponentProps: PropTypes.object
};

export default MenuActionButton;
